import { serializeFigure } from "@viuch/feature-instrument-settings/serializers/serializeFigure";
import { PolygonKinds } from "@viuch/instrument-figure2d-settings/entities/Polygon";

import type { TSerializedFigure2DSettings } from "@viuch/feature-instrument-settings/service-types/figure2d";
import type { Figure2DSettings } from "@viuch/instrument-figure2d-settings/entities";
import type { Polygon } from "@viuch/instrument-figure2d-settings/entities/Polygon";

export function serializeFigureSettings(settings: Figure2DSettings): TSerializedFigure2DSettings {
    const polygons: TSerializedFigure2DSettings.Polygon[] = [];
    const system: TSerializedFigure2DSettings.System[] = [];

    for (const sys of settings.systems) {
        system.push(sys);
    }

    for (const polygon of settings.polygons) {
        polygons.push(serializeFigure2DPolygon(polygon));
    }

    return {
        ...serializeFigure(settings.figureEditorInstance),
        polygons,
        system,
    };
}

function serializeFigure2DPolygon(polygon: Polygon): TSerializedFigure2DSettings.Polygon {
    const { kind, area, value, perimeter } = polygon;
    return {
        kind: serializeFigure2DPolygonKind(kind),
        area,
        perimeter,
        points: value,
    };
}

function serializeFigure2DPolygonKind(kind: PolygonKinds): TSerializedFigure2DSettings.PolygonKind | null {
    switch (kind) {
        case PolygonKinds.NoType:
            return null;
        case PolygonKinds.Rectangle:
            return "rectangle";
        case PolygonKinds.Square:
            return "square";
        case PolygonKinds.Parallelogram:
            return "parallelogram";
        case PolygonKinds.Rhomb:
            return "rhombus";
        case PolygonKinds.Trapezoid:
            return "trapezoid";
    }
}
