import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { generateSlug } from "@viuch/shared/utils/data/slug";

import type { ChangeEvent } from "react";

import { BaseInput } from "./BaseInput";

import styles from "./SlugInput.module.scss";

type Props = {
    title: string;
    text: string;
    slug: string;
    onChange: (value: string) => void;
    error?: string;
};

export const SlugInput = observer(function SlugInput({ title, text, onChange, slug, error }: Props) {
    const [isAutoMode, setIsAutoMode] = useState(() => slug === generateSlug(text));

    const [value, setValue] = useState(slug);

    const toggleAutoMode = () => setIsAutoMode((isAutoMode) => !isAutoMode);

    useEffect(() => {
        if (!isAutoMode) return;

        const slug = generateSlug(text);

        setValue(slug);
        onChange(slug);
    }, [text, isAutoMode, onChange]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };

    const handleBlur = () => {
        if (!value) {
            const slug = generateSlug(text);
            setValue(slug);
            onChange(slug);
        }
    };

    return (
        <BaseInput
            title={title}
            error={error}
        >
            <div className={styles.wrapper}>
                <input
                    disabled={isAutoMode}
                    title="Slug"
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={styles.input}
                />
                <div style={{ display: "flex", gap: 10 }}>
                    <div className={styles.autoModeCheckbox}>
                        <input
                            type="checkbox"
                            title="Автоматический режим"
                            checked={!isAutoMode}
                            onChange={toggleAutoMode}
                            className={styles.autoModeCheckbox__input}
                        />
                        <span
                            className={styles.autoModeCheckbox__label}
                            onClick={toggleAutoMode}
                        >
                            Ручной режим
                        </span>
                    </div>
                </div>
            </div>
        </BaseInput>
    );
});
