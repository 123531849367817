import { mapDate, serializeDate } from "@viuch/shared/serialization/dates";
import { mapDurationToSeconds } from "@viuch/shared/serialization/durations";
import { formatTimeSeconds } from "@viuch/shared/serialization/times";
import { generateId } from "@viuch/shared/utils/data";

import type { TRawExamRequest, TRawExamResponse, TRawExamTask } from "./ExamsService.types";

import { Exam, ExamTask } from "../../entities/exams";

export const mapExam = (data: TRawExamResponse): Exam => {
    const {
        id,
        name,
        short_name,
        tasks,
        slug,
        approximate_date,
        allowed_time,
        meta_title,
        meta_description,
        landing_meta_title,
        landing_meta_description,
        preamble,
        is_active,
        grades,
        target_goal,
        is_generated,
        priority,
        preparation_period,
        grading_display,
        grading_ranges,
    } = data;

    return new Exam({
        id,
        name,
        shortName: short_name,
        tasks: tasks.map(mapExamTask),
        allowedTimeSeconds: mapDurationToSeconds(allowed_time),
        approximateDate: mapDate(approximate_date),
        slug,
        metaTitle: meta_title,
        metaDescription: meta_description,
        landingMetaTitle: landing_meta_title,
        landingMetaDescription: landing_meta_description,
        preambleId: preamble,
        gradeIds: grades.map((g) => g.id),
        isArchived: !is_active,
        targetGoal: +target_goal,
        gradingDisplay: grading_display,
        gradingRanges: grading_ranges || {
            aliases: ["2", "3", "4", "5"],
            score_limits: [undefined, undefined, undefined],
        },
        isGenerated: is_generated,
        preparationPeriodSeconds: mapDurationToSeconds(preparation_period),
        priorityValue: priority,
    });
};

export const mapExamTask = ({ score, text_problems, themes, name }: TRawExamTask): ExamTask =>
    new ExamTask({
        id: generateId(),
        problemIds: text_problems,
        themeIds: themes,
        score,
        name,
    });

export const serializeExam = (exam: Exam): TRawExamRequest => {
    const {
        id,
        name,
        shortName,
        tasks,
        approximateDate,
        allowedTimeSeconds,
        slug,
        metaTitle,
        metaDescription,
        landingMetaTitle,
        landingMetaDescription,
        preambleId,
        gradeIds,
        gradingDisplay,
        gradingRanges,
        targetGoal,
        isArchived,
        preparationPeriodSeconds,
        priorityValue,
    } = exam;

    return {
        id,
        name,
        short_name: shortName,
        tasks: tasks.map(serializeExamTask),
        slug,
        allowed_time: formatTimeSeconds(allowedTimeSeconds),
        approximate_date: serializeDate(approximateDate),
        meta_title: metaTitle,
        landing_meta_title: landingMetaTitle,
        landing_meta_description: landingMetaDescription,
        meta_description: metaDescription,
        preamble: preambleId,
        grades: gradeIds,
        grading_display: gradingDisplay,
        grading_ranges: gradingRanges,
        target_goal: targetGoal,
        is_active: !isArchived,
        preparation_period: formatTimeSeconds(preparationPeriodSeconds),
        priority: priorityValue,
    };
};

export const serializeExamTask = ({ problemIds, score, themeIds, id, name }: ExamTask): TRawExamTask => ({
    themes: themeIds,
    text_problems: problemIds,
    score,
    id,
    name,
});
